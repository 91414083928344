import styles from "./css/ForSale.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";
import Footer from "../../Components/Footer/Footer";
import PageHeader from "../../Components/PageHeader/PageHeader";
import placeHolderHorseImage from "./images/horse_black_running_animal_silhouette.webp";
import useFetch from "../../Hooks/useFetch";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Horses = () => {
  const [sections, setSections] = useState([]);
  const { data: horsesForSale } = useFetch(
    "horses?filters[for_sale][$eq]=true&populate=*"
  );

  useEffect(() => {
    if (horsesForSale) {
      const sectionImagesArray = [];
      const seenSections = {};

      horsesForSale.data.forEach((horse) => {
        const section = horse.attributes.section;

        if (!seenSections[section]) {
          if (horse.attributes.featured_image.data) {
            if (horse.attributes.featured_image.data.attributes.formats.small) {
              const firstSmallImageUrl =
                horse.attributes.featured_image.data.attributes.formats.small
                  .url;

              sectionImagesArray.push({
                section,
                imageUrl: firstSmallImageUrl,
              });

              seenSections[section] = true;
            } else if (
              horse.attributes.featured_image.data.attributes.formats.thumbnail
            ) {
              const firstThumbnailImageUrl =
                horse.attributes.featured_image.data.attributes.formats
                  .thumbnail.url;

              sectionImagesArray.push({
                section,
                imageUrl: firstThumbnailImageUrl,
              });

              seenSections[section] = true;
            } else {
              sectionImagesArray.push({
                section,
              });
              seenSections[section] = true;
            }
          } else {
            sectionImagesArray.push({
              section,
            });
            seenSections[section] = true;
          }
        }
      });
      sectionImagesArray.sort((a, b) => a.section.localeCompare(b.section));
      setSections(sectionImagesArray);
    }
  }, [horsesForSale]);

  return (
    <>
      <NavBar />
      <PageHeader title={`For Sale`} />
      <section className={`${styles.forSaleSection} container`}>
        {sections.length > 0 ? (
          sections.map((section, index) => (
            <div className={styles.sectionCard} key={`section index ${index}`}>
              <Link to={`/forSale/${section.section}`}>
                {section.imageUrl ? (
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_API_URI + section.imageUrl}
                    alt={"Placeholder horse"}
                    width={300}
                    height={225}
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={placeHolderHorseImage}
                    alt={"Placeholder horse"}
                    width={300}
                    height={225}
                  />
                )}
              </Link>
              <h2>{section.section} Ponies</h2>

              <Link className={styles.btn} to={`/forSale/${section.section}`}>
                View {section.section} ponies
              </Link>
            </div>
          ))
        ) : (
          <div className={styles.queryMessage}>
            <p>No ponies currently for sale</p>
          </div>
        )}
      </section>

      <hr className={`${styles.separator} container`} />
      <FullWidthCTA textColor={"#966a53"} />
      <Footer />
    </>
  );
};
export default Horses;
