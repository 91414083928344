import { Link, useLocation } from "react-router-dom";

const CustomNavLink = ({ to, section, children }) => {
  const location = useLocation();
  const isActive = children.toLowerCase() === location.state?.section;
  const className = isActive ? "active" : "";

  return (
    <Link to={to} className={className} state={{ section: section }}>
      {children}
    </Link>
  );
};
export default CustomNavLink;
