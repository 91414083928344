import styles from "./css/Horse.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import PageHeader from "../../Components/PageHeader/PageHeader";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";
import Footer from "../../Components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../Hooks/useFetch";
import { useMemo, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import magGlass from "./images/mag_glass.png";
import FamilyTree from "../../Components/FamilyTree/FamilyTree";
import LightBox from "../../Components/LightBox/LightBox";
import fallbackHorseImage from "./images/horse_black_running_animal_silhouette.webp";

const Horse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // Fetch the horse data and store it in the currentHorse state
  const { data: currentHorse } = useFetch(`horses/${id}?populate=*`);

  const [currentImage, setCurrentImage] = useState(0);
  const [openLightBox, setOpenLightBox] = useState(false);

  // useMemo to store the image URLs
  const imageUrls = useMemo(() => {
    if (currentHorse?.data.attributes.images.data) {
      return currentHorse?.data.attributes.images.data.map(
        (image) => process.env.REACT_APP_API_URI + image.attributes.url
      );
    }
    return [];
  }, [currentHorse]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <NavBar />
      {currentHorse && (
        <PageHeader
          title={`${currentHorse?.data.attributes.section} - ${currentHorse?.data.attributes.gender} - ${currentHorse?.data.attributes.name}`}
        />
      )}
      {currentHorse && (
        <>
          <section className={`${styles.horseDetails} container`}>
            <button className={styles.backLink} onClick={goBack}>
              <span className={styles.backArrow}>&lt;</span> Back
            </button>
            <div className={styles.horseInfo}>
              {!!currentHorse.data.attributes.images.data ? (
                <div className={styles.imageContainer}>
                  <img
                    loading="lazy"
                    className={styles.mainImage}
                    src={imageUrls[currentImage]}
                    alt={
                      currentHorse.data.attributes.images.data[currentImage]
                        .attributes.alternativeText
                    }
                  />
                  <button
                    type="button"
                    className={styles.magGlassButton}
                    onClick={() => setOpenLightBox(true)}
                  >
                    <img
                      loading="lazy"
                      src={magGlass}
                      alt="magnifying glass icon"
                    />
                  </button>
                  <div className={styles.imageSelector}>
                    {currentHorse.data.attributes.images.data.map(
                      (image, index) => (
                        <div
                          className={styles.selectorThumbnail}
                          key={image.id}
                        >
                          <img
                            loading="lazy"
                            src={
                              process.env.REACT_APP_API_URI +
                              image.attributes.formats.thumbnail.url
                            }
                            alt={image.attributes.alternativeText}
                            onClick={() => {
                              setCurrentImage(index);
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.imageContainer}>
                  <img
                    loading="lazy"
                    className={styles.mainImage}
                    src={fallbackHorseImage}
                    alt="White horse silhouette on a dark background"
                  />
                </div>
              )}
              <div className={styles.horseTextContent}>
                <h2 className={styles.horseName}>
                  {currentHorse?.data.attributes.name}
                </h2>
                {/* Display only the year the horse was born - date format yyyy-mm-dd */}
                {currentHorse?.data.attributes.birth_date && (
                  <p className={styles.horseBirthYear}>
                    Born {currentHorse.data.attributes.birth_date.split("-")[0]}
                  </p>
                )}
                <p className={styles.horseSubHeading}>
                  {currentHorse?.data.attributes.sub_heading}
                </p>

                <p className={styles.horseSire}>
                  {currentHorse?.data.attributes.sire &&
                    "Sire: " + currentHorse.data.attributes.sire}
                </p>

                <p className={styles.horseDam}>
                  {currentHorse?.data.attributes.dam &&
                    "Dam: " + currentHorse.data.attributes.dam}
                </p>

                {currentHorse.data.attributes.by_stud && (
                  <p className={styles.horseByStud}>
                    by {currentHorse?.data.attributes.by_stud}
                  </p>
                )}
                {currentHorse.data.attributes.sold ? (
                  <p className={styles.soldHorse}>Sold</p>
                ) : (
                  currentHorse.data.attributes.for_sale && (
                    <p className={styles.forSaleHorse}>For Sale</p>
                  )
                )}
                <hr />
                <ReactMarkdown className={styles.horseDescription}>
                  {currentHorse?.data.attributes.description}
                </ReactMarkdown>
              </div>
            </div>
          </section>
          {currentHorse.data.attributes.parents.data.length > 0 && (
            <FamilyTree
              horseId={id}
              horseName={currentHorse?.data.attributes.name}
            />
          )}
        </>
      )}
      {currentHorse?.data.attributes.for_sale &&
      currentHorse?.data.attributes.sold === false ? (
        <FullWidthCTA
          textColor={"#ffffff"}
          backgroundColor={"#5e9653"}
          horseName={currentHorse.data.attributes.name}
        />
      ) : (
        <FullWidthCTA textColor={"#966a53"} />
      )}

      <Footer />
      {!!currentHorse?.data.attributes.images.data && (
        <LightBox
          images={currentHorse.data.attributes.images}
          currentImageIndex={currentImage}
          setCurrentImageIndex={setCurrentImage}
          openLightBox={openLightBox}
          setOpenLightBox={setOpenLightBox}
        />
      )}
    </>
  );
};
export default Horse;
