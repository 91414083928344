import styles from "./css/PageHeader.module.css";

const PageHeader = ({ title }) => {
  return (
    <section className={styles.pageHeader}>
      <h1>{title}</h1>
    </section>
  );
};
export default PageHeader;
