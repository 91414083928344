import Footer from "../../Components/Footer/Footer";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";
import NavBar from "../../Components/NavBar/NavBar";
import NewsArticles from "../../Components/NewsArticles/NewsArticles";
import PageHeader from "../../Components/PageHeader/PageHeader";

const News = () => {
  return (
    <>
      <NavBar />
      <PageHeader title={"Latest News"} />
      <NewsArticles perPage={8} />
      <FullWidthCTA backgroundColor={"#5e9653"} textColor={"#fff"} />
      <Footer />
    </>
  );
};
export default News;
