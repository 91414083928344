import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./css/Horses.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import OurHorses from "../../Components/OurHorses/OurHorses";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";
import Footer from "../../Components/Footer/Footer";
import PageHeader from "../../Components/PageHeader/PageHeader";

const Horses = () => {
  const [gender, setGender] = useState(null);
  const [archived, setArchived] = useState(null);
  const [section, setSection] = useState("section a");
  const [searchParams, setSearchParams] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearchParams(searchParams);
  }, [location.search]);

  useEffect(() => {
    if (searchParams.size > 0) {
      if (searchParams.get("archived") === "true") {
        setArchived(true);
        setGender(null);
      } else {
        setArchived(false);
        setGender(searchParams.get("gender"));
      }
    } else {
      setGender("male");
      setArchived(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (location.state?.section) {
      setSection(location.state.section);
    } else {
      setSection("section a");
    }
  }, [location?.state?.section]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [gender, section]);

  return (
    <>
      <NavBar />
      <PageHeader title={`${section}`} />
      <section className={styles.ourHorses}>
        <div className={styles.genderSelection}>
          <button
            className={gender === "male" ? styles.active : ""}
            onClick={() => {
              searchParams.set("gender", "male");
              searchParams.delete("archived");
              navigate(`?${searchParams.toString()}`, {
                state: location.state,
              });
            }}
          >
            Male
          </button>
          <button
            className={gender === "female" ? styles.active : ""}
            onClick={() => {
              searchParams.set("gender", "female");
              searchParams.delete("archived");
              navigate(`?${searchParams.toString()}`, {
                state: location.state,
              });
            }}
          >
            Female
          </button>
          <button
            className={archived ? styles.active : ""}
            onClick={() => {
              searchParams.set("archived", "true");
              searchParams.delete("gender");
              navigate(`?${searchParams.toString()}`, {
                state: location.state,
              });
            }}
          >
            Archived
          </button>
        </div>
        <div className={styles.horses}>
          {archived ? (
            <OurHorses
              state={location.state}
              filters={{
                "filters[archived][$eq]": archived,
                "filters[section][$eq]": section,
                populate: "*",
                sort: "createdAt",
              }}
            />
          ) : (
            <OurHorses
              state={location.state}
              filters={{
                "filters[archived][$eq]": archived,
                "filters[for_sale][$eq]": false,
                "filters[gender][$eq]": gender,
                "filters[section][$eq]": section,
                populate: "*",
                sort: "createdAt",
              }}
            />
          )}
        </div>
      </section>
      <div className={styles.forSaleLinkContainer}>
        <Link to={`/forsale/${section}`} className={styles.forSaleLink}>
          View our {section && section} ponies for sale
        </Link>
      </div>
      <hr className={`${styles.separator} container`} />
      <FullWidthCTA textColor={"#966a53"} />
      <Footer />
    </>
  );
};
export default Horses;
