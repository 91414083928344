import styles from "./css/PrevNextArticles.module.css";
import useFetch from "../../Hooks/useFetch";
import { Link } from "react-router-dom";

const PrevNextArticles = ({ currentArticleId }) => {
  const { data: previousArticle } = useFetch(
    `news-articles?populate=*&pagination[pageSize]=1&filters[id][$lt]=${currentArticleId}&sort[0]=id:desc`
  );

  const { data: nextArticle } = useFetch(
    `news-articles?populate=*&pagination[pageSize]=1&filters[id][$gt]=${currentArticleId}`
  );

  return (
    <section className={`${styles.prevNextArticles} container`}>
      {previousArticle?.data.length > 0 && (
        <div className={styles.previousArticle}>
          <Link to={`/news/${previousArticle.data[0].id}`}>
            <img
              src={
                process.env.REACT_APP_API_URI +
                previousArticle.data[0].attributes.featured_image.data
                  .attributes.formats.thumbnail.url
              }
              alt=""
              height={75}
              width={75}
              className={styles.prevNextImage}
            />
            <div className="">
              <p className={styles.prevNextText}>Previous Post</p>
              <p className={styles.prevNextTitle}>
                {previousArticle.data[0].attributes.title.substring(0, 30) +
                  " ..."}
              </p>
            </div>
          </Link>
        </div>
      )}
      {nextArticle?.data.length > 0 && (
        <div className={styles.nextArticle}>
          <Link to={`/news/${nextArticle.data[0].id}`}>
            <div className="">
              <p className={styles.prevNextText}>Next Post</p>
              <p className={styles.prevNextTitle}>
                {nextArticle.data[0].attributes.title.substring(0, 30) + " ..."}
              </p>
            </div>
            <img
              src={
                process.env.REACT_APP_API_URI +
                nextArticle.data[0].attributes.featured_image.data.attributes
                  .formats.thumbnail.url
              }
              alt=""
              height={75}
              width={75}
              className={styles.prevNextImage}
            />
          </Link>
        </div>
      )}
    </section>
  );
};
export default PrevNextArticles;
