const GoogleMap = () => {
  return (
    <iframe
      src={
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.0410401788267!2d-4.2867536230631895!3d52.078982768787064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486f1b6203103d13%3A0x4e966e8057cb50a!2sMenai%20%26%20Friarly%20Studs!5e0!3m2!1sen!2suk!4v1693462360258!5m2!1sen!2suk"
      }
      width={"100%"}
      height={"400"}
      style={{ border: 0 }}
      allowFullScreen={true}
      loading={"lazy"}
      referrerPolicy={"no-referrer-when-downgrade"}
      title={"menai stud location"}
    ></iframe>
  );
};
export default GoogleMap;
