import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./css/ForSaleHorses.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import PageHeader from "../../Components/PageHeader/PageHeader";
import { useEffect, useMemo, useState } from "react";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";
import Footer from "../../Components/Footer/Footer";
import HorsesForSale from "../../Components/HorsesForSale/HorsesForSale";
const ForSaleHorses = () => {
  const { section } = useParams();
  const [classification, setClassification] = useState("");
  const [searchParams, setSearchParams] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [classification]);

  useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearchParams(searchParams);
  }, [location.search]);

  useEffect(() => {
    if (searchParams.size > 0) {
      setClassification(searchParams.get("classification"));
    } else {
      setClassification("");
    }
  }, [searchParams]);

  const handleClassificationClick = (classification) => {
    searchParams.set("classification", classification);
    setClassification(classification);
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      <NavBar />
      <PageHeader title={`${section} - For Sale`} />
      <div className={styles.classificationSelector}>
        <button
          className={classification === "foal" ? styles.active : ""}
          onClick={() => handleClassificationClick("foal")}
        >
          Foals
        </button>
        <button
          className={classification === "youngstock" ? styles.active : ""}
          onClick={() => handleClassificationClick("youngstock")}
        >
          Youngstock
        </button>
        <button
          className={classification === "adult" ? styles.active : ""}
          onClick={() => handleClassificationClick("adult")}
        >
          Adults
        </button>
        <button
          className={classification === "gelding" ? styles.active : ""}
          onClick={() => handleClassificationClick("gelding")}
        >
          Geldings
        </button>
        <button
          className={classification === "" ? styles.active : ""}
          onClick={() => handleClassificationClick("")}
        >
          All
        </button>
      </div>
      {classification !== "" ? (
        <HorsesForSale
          filters={{
            "filters[for_sale][$eq]": true,
            "filters[section][$eq]": section,
            "filters[classification][$eq]": classification,
            populate: "*",
            sort: "createdAt",
          }}
        />
      ) : (
        <HorsesForSale
          filters={{
            "filters[for_sale][$eq]": true,
            "filters[section][$eq]": section,
            populate: "*",
            sort: "createdAt",
          }}
        />
      )}

      <hr className={`${styles.separator} container`} />
      <FullWidthCTA textColor={"#966a53"} />
      <Footer />
    </>
  );
};
export default ForSaleHorses;
