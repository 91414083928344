import styles from "./css/FeaturedPonies.module.css";
import placeHolderHorseImage from "./images/horse_black_running_animal_silhouette.webp";
import useFetch from "../../Hooks/useFetch";
import { Link } from "react-router-dom";

const FeaturedPonies = () => {
  const { data: featuredTitle } = useFetch("homepage-feature-title");

  const {
    data: horses,
    loading,
    error,
  } = useFetch("horses?populate=*&filters[featured_on_homepage][$eq]=true");
  return (
    <div className="container">
      <h1 className={styles.FeaturedPonyHeading}>
        {featuredTitle && featuredTitle.data.attributes.title}
      </h1>
      <section className={styles.FeaturedPoniesSection}>
        {loading ? (
          <>
            <div className={styles.loadingCard}>
              <div className={styles.loadingImage}></div>
              <div className={styles.loadingName}></div>
              <div className={styles.loadingSire}></div>
              <div className={styles.loadingDame}></div>
              <div className={styles.loadingBy}></div>
              <div className={styles.loadingButton}></div>
            </div>
            <div className={styles.loadingCard}>
              <div className={styles.loadingImage}></div>
              <div className={styles.loadingName}></div>
              <div className={styles.loadingSire}></div>
              <div className={styles.loadingDame}></div>
              <div className={styles.loadingBy}></div>
              <div className={styles.loadingButton}></div>
            </div>
          </>
        ) : error ? (
          <div className={styles.errorMessage}>
            <p>Error Loading Our Ponies...</p>
            <p>Please try again later</p>
          </div>
        ) : horses && horses.data?.length > 0 ? (
          horses?.data.map((horse) => (
            <div className={styles.card} key={horse.id}>
              <Link to={`/horse/${horse.id}`} className={styles.ImageLink}>
                {
                  // Check if an image is available, if not show placeholder image
                  horse.attributes.featured_image.data != null ? (
                    // If image is available, check if small image is available else, render thumbnail image.
                    horse.attributes.featured_image.data.attributes.formats
                      .small ? (
                      // Small Image
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_API_URI +
                          horse.attributes.featured_image.data.attributes
                            .formats.small.url
                        }
                        alt={`Thumbnail of ${horse.attributes.name}`}
                      />
                    ) : (
                      // Thumbnail Image
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_API_URI +
                          horse.attributes.featured_image.data.attributes
                            .formats.thumbnail.url
                        }
                        alt={`Thumbnail of ${horse.attributes.name}`}
                      />
                    )
                  ) : (
                    // Placeholder Image
                    <img
                      loading="lazy"
                      src={placeHolderHorseImage}
                      alt={`Thumbnail of ${horse.attributes.name}`}
                      width={300}
                      height={225}
                    />
                  )
                }
              </Link>
              <h2>{horse.attributes.name}</h2>
              {horse.attributes.sold ? (
                <p className={styles.soldHorse}>Sold</p>
              ) : (
                horse.attributes.for_sale && (
                  <p className={styles.forSaleHorse}>For Sale</p>
                )
              )}
              {
                <p className={styles.sireDamNames}>
                  Sire: {horse.attributes.sire && horse.attributes.sire}
                </p>
              }
              {
                <p className={styles.sireDamNames}>
                  Dam: {horse.attributes.dam && horse.attributes.dam}
                </p>
              }
              {horse.attributes.breeder && (
                <p className={styles.sireDamNames}>
                  by {horse.attributes.breeder}
                </p>
              )}
              <Link className={styles.btn} to={`/horse/${horse.id}`}>
                View Full Pony Details
              </Link>
            </div>
          ))
        ) : (
          <div className={styles.queryMessage}>
            <p>No featured ponies found</p>
          </div>
        )}
      </section>
    </div>
  );
};
export default FeaturedPonies;
