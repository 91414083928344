import styles from "./css/NewsArticle.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Footer from "../../Components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import RecentArticles from "../../Components/RecentArticles/RecentArticles";
import useFetch from "../../Hooks/useFetch";
import PrevNextArticles from "../../Components/PrevNextArticles/PrevNextArticles";

const NewsArticle = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const params = useParams();

  const { data: currentArticle } = useFetch(
    `news-articles/${params.id}?populate=*`
  );

  return (
    <>
      <NavBar />
      <PageHeader title={"Latest News"} />
      <div className={`${styles.backButtonContainer} container`}>
        <button className={styles.backLink} onClick={goBack}>
          <span className={styles.backArrow}>&lt;</span> Back
        </button>
      </div>
      {currentArticle && (
        <>
          <div className={`${styles.articleContainer} container`}>
            <section className={styles.article}>
              {currentArticle.data.attributes.featured_image.data.attributes
                .formats.medium ? (
                <img
                  src={
                    process.env.REACT_APP_API_URI +
                    currentArticle.data.attributes.featured_image.data
                      .attributes.formats.medium.url
                  }
                  alt=""
                />
              ) : currentArticle.data.attributes.featured_image.data.attributes
                  .formats.small ? (
                <img
                  src={
                    process.env.REACT_APP_API_URI +
                    currentArticle.data.attributes.featured_image.data
                      .attributes.formats.small.url
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    process.env.REACT_APP_API_URI +
                    currentArticle.data.attributes.featured_image.data
                      .attributes.formats.thumbnail.url
                  }
                  alt=""
                />
              )}

              <h1 className={styles.articleTitle}>
                {currentArticle.data.attributes.title}
              </h1>
              <ReactMarkdown className={styles.textContent}>
                {currentArticle.data.attributes.content}
              </ReactMarkdown>
              <p className={styles.articleDate}>
                {new Date(
                  currentArticle.data.attributes.article_date
                ).toLocaleDateString("en-GB", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </p>
              <PrevNextArticles currentArticleId={params.id} />
            </section>
            <RecentArticles />
          </div>
        </>
      )}
      <Footer />
    </>
  );
};
export default NewsArticle;
