import styles from "./css/AboutUs.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Footer from "../../Components/Footer/Footer";
import useFetch from "../../Hooks/useFetch";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import pjExportLogo from "./images/pj_exports_logo.webp";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";

const AboutUs = () => {
  const { data: histories } = useFetch("histories?populate=*&sort=createdAt");
  return (
    <>
      <NavBar />
      <PageHeader title={"About Us"} />
      <section className={styles.aboutUsSection}>
        <div className={"container"}>
          <p className={styles.sectionHeadText}>
            Peter Jones, with his wife Ann took over the Menai Stud on the death
            of Peter’s father, the late Willie Jones. The Stud was founded by
            Peter's great grandfather John Jones, and his grandfather the late
            William Jones at the turn of the century, with registrations in the
            first few volumes of the Welsh Pony and Cob Society Stud Books.
          </p>
          <div className={styles.historiesContainer}>
            {histories?.data &&
              histories.data.map((history) => (
                <div className={styles.history} key={history.id}>
                  <div className={styles.textContent}>
                    <ReactMarkdown>
                      {history.attributes.textContent}
                    </ReactMarkdown>
                  </div>
                  {history.attributes.images?.data && (
                    <div
                      className={styles.images}
                      key={history.attributes.images.data.id}
                    >
                      {history.attributes.images.data.map((image) => (
                        <div className={styles.imageContainer} key={image.id}>
                          <img
                            loading="lazy"
                            src={
                              process.env.REACT_APP_API_URI +
                              image.attributes.url
                            }
                            alt={image.attributes.caption}
                          />
                          <p>{image.attributes.caption}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.infoCTA}>
          <div className="container">
            <img loading="lazy" src={pjExportLogo} alt="" />
            <p className={styles.infoCTATextContent}>
              Some thirty years ago in order to further the interest in Welsh
              Ponies and Cobs on the Continent, and to enable overseas clients
              to purchase animals in Wales with confidence, Peter Jones started
              a new business of providing an Export Service for overseas clients
              called P.J. Exports. From modest beginnings P.J. Exports has
              exported hundreds of Welsh Ponies and Cobs and other native breeds
              all over the world. November 14th 1994 saw the biggest shipment of
              Welsh Ponies and Cobs to have crossed the Atlantic for 35 years,
              and the biggest shipment ever by air when no fewer than 32 Ponies
              and Cobs from all Sections arrived safely at Toronto for
              distribution through North America, of these 15 were Menai bred.
              Since then P.J.Exports have been responsible for the safe shipment
              of over 500 horses and ponies of all breeds including bloodstock
              by air to Canada and North America. P.J.Exports continues to
              provide an export Service to North America and after a gap of a
              few years, 2012 saw a consignement safely delivered via Toronto of
              with another shipment scheduled for November 2013.
            </p>
          </div>
        </div>
        <div className={`${styles.sectionFootText} ${"container"}`}>
          <p>
            Menai ponies and cobs continue to enjoy tremendous success in the
            show ring, and their bloodlines are sought after both in this
            country and all over the world. Peter and Ann find it very
            gratifying that both their sons, Richard and Thomas, have inherited
            such a keen interest in the ponies and cobs. They have also derived
            a lot of satisfaction from seeing their home bred cobs and ponies
            enjoying so much success both in hand and under saddle. No doubt
            that with such enthusiasm the future of both the Menai and Friarly
            Studs is secure.
          </p>
        </div>
      </section>
      <FullWidthCTA textColor={"#966a53"} />
      <Footer />
    </>
  );
};
export default AboutUs;
