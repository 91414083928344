import styles from "./css/Footer.module.css";
import locationPin from "./images/location_pin.svg";
import facebookLogo from "./images/footer_facebook_logo.svg";
import instagramLogo from "./images/footer_instagram_logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footerInfo}>
            <div className={styles.location}>
              <img
                loading="lazy"
                src={locationPin}
                alt="location pin icon"
                height={24}
                width={17}
              />
              <p>Menai Stud, Pantydefaid, Llandysul, Ceredigion</p>
            </div>
            <div className={styles.description}>
              <h5>Menai &amp; Friarly Studs</h5>
              <p>
                Breeding quality, true to type welsh ponies and cobs for over
                100 years. Come visit us and see for yourself.
              </p>
            </div>
            <div className={styles.contactNumber}>
              <h6>
                <a href="tel:+441545590503">Call: (+44) 01545 590 503</a>
              </h6>
            </div>
          </div>
          <div className={styles.footerNavigation}>
            <ul className={styles.navLinks}>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/horses"} state={{ section: "section a" }}>
                  Section A
                </Link>
              </li>
              <li>
                <Link to={"/horses"} state={{ section: "section c" }}>
                  Section C
                </Link>
              </li>
              <li>
                <Link to={"/horses"} state={{ section: "section d" }}>
                  Section D
                </Link>
              </li>
              <li>
                <Link to={"/horses"} state={{ section: "part bred" }}>
                  Part Bred
                </Link>
              </li>
              <li>
                <Link to={"/forSale"}>For Sale</Link>
              </li>
            </ul>
          </div>
          <div className={styles.footerNavigationSecondary}>
            <ul className={styles.extraLinks}>
              <li>
                <Link to={"/about"}>About Us</Link>
              </li>
              <li>
                <Link to={"/news"}>News</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className={styles.footerSocials}>
            <a
              className={styles.socialIcon}
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/p/Menai-Friarly-Studs-100077507718406/"
            >
              <img
                loading="lazy"
                src={facebookLogo}
                alt="Facebook round logo"
                height={"32"}
                width={"32"}
              />
            </a>
            <a
              className={styles.socialIcon}
              href="https://www.instagram.com/menaistud/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={instagramLogo}
                alt="Facebook round logo"
                height={"32"}
                width={"28"}
              />
            </a>
          </div>
        </div>
      </footer>
      <footer className={styles.footerMobile}>
        <h5>Menai &amp; Friarly Studs</h5>
        <p>
          Breeding quality, true to type welsh ponies and cobs for over 100
          years. Come visit us and see for yourself.
        </p>
        <h6>
          <a href="tel:+441545590503">Call: (+44) 01545 590 503</a>
        </h6>
        <p>Menai Stud, Pantydefaid, Llandysul, Ceredigion.</p>
        <div className={styles.socials}>
          <a
            href="https://www.facebook.com/p/Menai-Friarly-Studs-100077507718406/"
            className={styles.socialIcon}
            target="_blank"
            rel="noreferrer"
          >
            <img
              loading="lazy"
              height={"32"}
              width={"32"}
              src={facebookLogo}
              alt="Facebook round logo"
            />
          </a>
          <a
            href="https://www.instagram.com/menaistud/"
            className={styles.socialIcon}
            target="_blank"
            rel="noreferrer"
          >
            <img
              height={"32"}
              width={"28"}
              loading="lazy"
              src={instagramLogo}
              alt="Facebook round logo"
            />
          </a>
        </div>
      </footer>
    </>
  );
};
export default Footer;
