import styles from "./css/HorsesForSale.module.css";
import useFetch from "../../Hooks/useFetch";
import placeHolderHorseImage from "./images/horse_black_running_animal_silhouette.webp";
import { Link } from "react-router-dom";

const HorsesForSale = ({ filters, title }) => {
  let fullQueryString = "";

  if (filters) {
    const queryString = Object.keys(filters)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
      )
      .join("&");

    fullQueryString = `?${queryString}`;
  }

  const { data: horses, loading, error } = useFetch("horses" + fullQueryString);

  return (
    <div className="container">
      {title ? <h1 className={styles.OurHorseHeading}>{title}</h1> : ""}
      <section className={styles.ForSaleSection}>
        {loading ? (
          <>
            <div className={styles.loadingCard}>
              <div className={styles.loadingImage}></div>
              <div className={styles.loadingName}></div>
              <div className={styles.loadingSire}></div>
              <div className={styles.loadingDame}></div>
              <div className={styles.loadingBy}></div>
              <div className={styles.loadingButton}></div>
            </div>
            <div className={styles.loadingCard}>
              <div className={styles.loadingImage}></div>
              <div className={styles.loadingName}></div>
              <div className={styles.loadingSire}></div>
              <div className={styles.loadingDame}></div>
              <div className={styles.loadingBy}></div>
              <div className={styles.loadingButton}></div>
            </div>
          </>
        ) : error ? (
          <div className={styles.errorMessage}>
            <p>Error Loading Our Ponies...</p>
            <p>Please try again later</p>
          </div>
        ) : horses && horses.data?.length > 0 ? (
          horses?.data.map((horse) => (
            <div className={styles.card} key={horse.id}>
              <Link to={`/horse/${horse.id}`} className={styles.ImageLink}>
                {
                  // Check if an image is available, if not show placeholder image
                  horse.attributes.featured_image.data != null ? (
                    // If image is available, check if small image is available else, render thumbnail image.
                    horse.attributes.featured_image.data.attributes.formats
                      .small ? (
                      // Small Image
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_API_URI +
                          horse.attributes.featured_image.data.attributes
                            .formats.small.url
                        }
                        alt={`Thumbnail of ${horse.attributes.name}`}
                      />
                    ) : (
                      // Thumbnail Image
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_API_URI +
                          horse.attributes.featured_image.data.attributes
                            .formats.thumbnail.url
                        }
                        alt={`Thumbnail of ${horse.attributes.name}`}
                      />
                    )
                  ) : (
                    // Placeholder Image
                    <img
                      loading="lazy"
                      src={placeHolderHorseImage}
                      alt={`Thumbnail of ${horse.attributes.name}`}
                    />
                  )
                }
              </Link>
              <h2>{horse.attributes.name}</h2>
              {horse.attributes.sold ? (
                <p className={styles.soldHorse}>Sold</p>
              ) : (
                <p className={styles.forSaleHorse}>For Sale</p>
              )}
              {
                <p className={styles.sireDamNames}>
                  Sire: {horse.attributes.sire && horse.attributes.sire}
                </p>
              }
              {
                <p className={styles.sireDamNames}>
                  Dam: {horse.attributes.dam && horse.attributes.dam}
                </p>
              }
              {horse.attributes.gender && (
                <p className={styles.gender}>
                  Gender: {horse.attributes.gender}
                </p>
              )}
              <Link className={styles.btn} to={`/horse/${horse.id}`}>
                View Full Pony Details
              </Link>
            </div>
          ))
        ) : (
          <div className={styles.queryMessage}>
            <p>No ponies found for this category</p>
          </div>
        )}
      </section>
    </div>
  );
};
export default HorsesForSale;
