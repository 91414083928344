import { useEffect, useState } from "react";
import styles from "./css/LightBox.module.css";

const LightBox = ({
  images,
  currentImageIndex,
  setCurrentImageIndex,
  openLightBox,
  setOpenLightBox,
}) => {
  const handleClose = () => setOpenLightBox(false);
  const [numImages, setNumImages] = useState(0);

  useEffect(() => {
    setNumImages(images.data.length);
  }, [images]);

  const handleChangeImage = (direction) => {
    if (direction === "decrement" && currentImageIndex > 0) {
      setCurrentImageIndex((current) => current - 1);
    }

    if (direction === "increment" && currentImageIndex < numImages - 1) {
      setCurrentImageIndex((current) => current + 1);
    }
  };

  return (
    <div
      className={`${styles.lightBoxContainer} ${
        openLightBox ? styles.visible : ""
      }`}
    >
      <div className={styles.imageContainer}>
        <button
          type="button"
          className={styles.closeButton}
          onClick={handleClose}
        >
          &#x2715;
        </button>
        {currentImageIndex > 0 ? (
          <button
            type="button"
            className={styles.leftButton}
            onClick={() => {
              handleChangeImage("decrement");
            }}
          >
            &lt;
          </button>
        ) : (
          ""
        )}
        <img
          loading="lazy"
          src={
            process.env.REACT_APP_API_URI +
            images.data[currentImageIndex].attributes.url
          }
          alt={images.data[currentImageIndex].attributes.caption}
        />
        {currentImageIndex < numImages - 1 ? (
          <button
            type="button"
            className={styles.rightButton}
            onClick={() => {
              handleChangeImage("increment");
            }}
          >
            &gt;
          </button>
        ) : (
          ""
        )}
      </div>
      <p>{images.data[currentImageIndex].attributes.caption}</p>
    </div>
  );
};
export default LightBox;
