import styles from "./css/Home.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import heroImage from "./images/hero_image.webp";
import CtaButton from "../../Components/CtaButton/CtaButton";
import heroDownArrow from "./images/hero_down_arrow.webp";
import FullWidthCTA from "../../Components/FullWidthCTA/FullWidthCTA";
import Footer from "../../Components/Footer/Footer";
import FeaturedPonies from "../../Components/FeaturedPonies/FeaturedPonies";
import NewsArticles from "../../Components/NewsArticles/NewsArticles";
import PrizeWinner from "../../Components/PrizeWinner/PrizeWinner";
import { useRef } from "react";

const Home = () => {
  const firstContent = useRef(null);
  return (
    <>
      <NavBar />
      <section className={styles.hero}>
        <div className={styles.heroImageContainer}>
          <img
            className={styles.heroImage}
            src={heroImage}
            alt="light brown horse with blue sky in the background"
            loading="eager"
          />
          <div className={styles.imageGradient}></div>
          <div className={styles.imageGradientMobile}></div>
        </div>
        <div className={styles.heroText}>
          <p>
            Breeding quality, true to type welsh ponies and cobs for over 100
            years. Come visit us and see for yourself.
          </p>
          <CtaButton
            href={"tel:+441545590503"}
            text={"Call: (+44) 01545 590 503"}
          />
          <img
            loading="eager"
            height={"20"}
            width={"35"}
            className={styles.downArrow}
            src={heroDownArrow}
            alt="down arrow"
            onClick={() => {
              const offset = 50; // You can change this value to your desired offset
              const element = firstContent.current;

              if (element) {
                const elementPosition =
                  element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                  top: elementPosition - offset,
                  behavior: "smooth", // You can change this to 'auto' for instant scrolling
                });
              }
            }}
          />
        </div>
      </section>
      <section className={styles.introduction} ref={firstContent}>
        <div className={"container"}>
          <h1>Welcome to Menai &amp; Friarly Studs</h1>
          <p>
            Welcome to the Menai &amp; Friarly Studs based in the heart of
            Cardiganshire and run by Peter &amp; Ann Jones and their two sons,
            Richard &amp; Thomas. We endeavour to breed Ponies &amp; Cobs of the
            highest calibre to succeed in all disciplines and to make dreams
            come true.
          </p>
        </div>
      </section>
      <PrizeWinner />
      <FeaturedPonies />
      <FullWidthCTA backgroundColor={"#5e9653"} textColor={"#fff"} />
      <NewsArticles showMoreLink={true} showSectionHeading={true} perPage={4} />
      <Footer />
    </>
  );
};
export default Home;
