import { Link, NavLink } from "react-router-dom";
import styles from "./css/NavBar.module.css";
import locationPin from "./images/location_pin.svg";
import facebookIcon from "./images/nav_facebook_icon.svg";
import instagramIcon from "./images/nav_instagram_logo.svg";
import { useState } from "react";
import CtaButton from "../CtaButton/CtaButton";
import CustomNavLink from "../CustomNavLink/CustomNavLink";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={"container"}>
      <header className={styles.header}>
        <div className={styles.location}>
          <img
            loading="lazy"
            src={locationPin}
            alt="location pin icon"
            height={24}
            width={17}
          />
          <p>Menai Stud, Pantydefaid, Llandysul, Ceredigion</p>
        </div>
        <ul className={styles.links}>
          <li>
            <Link to={"/about"}>About Us</Link>
          </li>
          <li>
            <Link to={"/news"}>News</Link>
          </li>
          <li>
            <Link to={"/contact"}>Contact</Link>
          </li>
          <li>
            <a
              href="https://www.facebook.com/p/Menai-Friarly-Studs-100077507718406/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={facebookIcon}
                alt="facebook icon"
                height={26}
                width={26}
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/menaistud/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={instagramIcon}
                alt="facebook icon"
                height={26}
                width={26}
              />
            </a>
          </li>
        </ul>
      </header>
      {/* DESKTOP NAV */}
      <nav className={styles.navBar}>
        <Link to={"/"} className={styles.logo}>
          <h1>Menai &amp;</h1>
          <h1>Friarly Studs</h1>
        </Link>
        <ul className={styles.navLinks}>
          <li>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to={"/"}
            >
              Home
            </NavLink>
          </li>
          <li>
            <CustomNavLink to={"/horses"} section={"section a"}>
              Section A
            </CustomNavLink>
          </li>
          <li>
            <CustomNavLink to={"/horses"} section={"section c"}>
              Section C
            </CustomNavLink>
          </li>
          <li>
            <CustomNavLink to={"/horses"} section={"section d"}>
              Section D
            </CustomNavLink>
          </li>
          <li>
            <CustomNavLink to={"/horses"} section={"part bred"}>
              Part Bred
            </CustomNavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to={"/forSale"}
            >
              For Sale
            </NavLink>
          </li>
        </ul>
        <CtaButton
          href={"tel:+441545590503"}
          text={"Call: (+44) 01545 590 503"}
        />
      </nav>
      {/* MOBILE NAV */}
      <nav className={styles.mobileNav}>
        <Link
          to={"/"}
          className={styles.logo}
          onClick={() => setMenuOpen(false)}
        >
          <h1>Menai &amp;</h1>
          <h1>Friarly Studs</h1>
        </Link>
        <button
          type="button"
          className={`${styles.menuButton} ${menuOpen ? styles.open : ""}`}
          onClick={() => setMenuOpen((currentState) => !currentState)}
          aria-label="menu toggle button"
        >
          {/* Styled To Produce Menu Button */}
          <div></div>
          <div></div>
          <div></div>
        </button>
      </nav>
      <div
        className={`${styles.menu} ${menuOpen ? styles.open : ""}`}
        onClick={(e) => setMenuOpen(false)}
      >
        <div className={styles.menuContainer}>
          <ul className={styles.navLinks}>
            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={"/"}
                onClick={() => setMenuOpen(false)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <CustomNavLink
                to={"/horses"}
                section={"section a"}
                onClick={() => setMenuOpen(false)}
              >
                Section A
              </CustomNavLink>
            </li>
            <li>
              <CustomNavLink
                to={"/horses"}
                section={"section c"}
                onClick={() => setMenuOpen(false)}
              >
                Section C
              </CustomNavLink>
            </li>
            <li>
              <CustomNavLink
                to={"/horses"}
                section={"section d"}
                onClick={() => setMenuOpen(false)}
              >
                Section D
              </CustomNavLink>
            </li>
            <li>
              <CustomNavLink
                to={"/horses"}
                section={"part bred"}
                onClick={() => setMenuOpen(false)}
              >
                Part Bred
              </CustomNavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={"/forSale"}
                onClick={() => setMenuOpen(false)}
              >
                For Sale
              </NavLink>
            </li>
          </ul>
          <CtaButton
            href={"tel:+441545590503"}
            text={"Call: (+44) 01545 590 503"}
          />
          <ul className={styles.extraLinks}>
            <li>
              <Link to={"/about"} onClick={() => setMenuOpen(false)}>
                About Us
              </Link>
            </li>
            <li>
              <Link to={"/news"} onClick={() => setMenuOpen(false)}>
                News
              </Link>
            </li>
            <li>
              <Link to={"/contact"} onClick={() => setMenuOpen(false)}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
