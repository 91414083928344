import useFetch from "../../Hooks/useFetch";
import styles from "./css/RecentArticles.module.css";
import { Link } from "react-router-dom";

const RecentArticles = () => {
  const { data: recentArticles } = useFetch(
    "news-articles?populate=*&sort[0]=article_date:desc&pagination[pageSize]=5"
  );

  return (
    <aside className={styles.recentArticles}>
      {recentArticles &&
        recentArticles.data.map((recentArticle) => (
          <Link
            to={`/news/${recentArticle.id}`}
            key={`recent article ${recentArticle.id}`}
          >
            <div className={styles.recentArticle}>
              <div className={styles.recentArticleImageContainer}>
                <img
                  src={
                    process.env.REACT_APP_API_URI +
                    recentArticle.attributes.featured_image.data.attributes
                      .formats.thumbnail.url
                  }
                  alt=""
                  height={75}
                  width={75}
                />
              </div>
              <div className="">
                <p>{recentArticle.attributes.title}</p>
                <p>
                  <small>
                    {new Date(
                      recentArticle.attributes.article_date
                    ).toLocaleDateString("en-GB", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </small>
                </p>
              </div>
            </div>
          </Link>
        ))}
    </aside>
  );
};
export default RecentArticles;
