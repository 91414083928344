import useFetch from "../../Hooks/useFetch";
import styles from "./css/PrizeWinner.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useRef } from "react";
import sliderLeftArrow from "./images/sliderArrowLeft.webp";
import sliderRightArrow from "./images/sliderArrowRight.webp";

const PrizeWinner = () => {
  const slider = useRef(null);
  const { data: prizeWinners } = useFetch(
    "horses?populate=*&filters[is_prize_winner][$eq]=true"
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1121,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 763,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    prizeWinners?.data.length > 0 && (
      <section className={`${styles.prizeWinners}`}>
        <h1 className={styles.sectionTitle}>Prize Winners</h1>

        <button
          onClick={() => slider?.current?.slickPrev()}
          className={styles.sliderArrowPrevious}
        >
          <img src={sliderLeftArrow} alt="Previous Slide" />
        </button>

        <button
          onClick={() => slider?.current?.slickNext()}
          className={styles.sliderArrowNext}
        >
          <img src={sliderRightArrow} alt="Next Slide" />
        </button>

        <Slider
          {...sliderSettings}
          className={styles.imageSlider}
          ref={slider}
          autoplay
        >
          {prizeWinners.data.map((prizeWinner) => (
            <div className={styles.prizeWinner} key={prizeWinner.id}>
              {prizeWinner.attributes.featured_image.data.attributes.formats
                .small ? (
                <img
                  src={
                    process.env.REACT_APP_API_URI +
                    prizeWinner.attributes.featured_image.data.attributes
                      .formats.small.url
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    process.env.REACT_APP_API_URI +
                    prizeWinner.attributes.featured_image.data.attributes
                      .formats.thumbnail.url
                  }
                  alt=""
                />
              )}
              <div className={styles.textContainer}>
                <h3>
                  {prizeWinner.attributes.prize_winner_text.length > 90
                    ? prizeWinner.attributes.prize_winner_text.substring(
                        0,
                        90
                      ) + " ..."
                    : prizeWinner.attributes.prize_winner_text}
                </h3>
                <Link
                  to={`/horse/${prizeWinner.id}`}
                  className={styles.ponyLink}
                >
                  View Full Pony Details
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    )
  );
};
export default PrizeWinner;
