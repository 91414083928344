import styles from "./css/CtaButton.module.css";

const CtaButton = ({ href, text }) => {
  return (
    <a className={styles.ctaButton} href={href}>
      {text}
    </a>
  );
};
export default CtaButton;
