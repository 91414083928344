import styles from "./css/FullWidthCTA.module.css";

const FullWidthCTA = ({ backgroundColor, textColor, horseName }) => {
  return (
    <section
      className={styles.fullWidthCTA}
      style={{ backgroundColor: backgroundColor }}
    >
      <h2 style={{ color: textColor }}>
        You are most welcome to come and visit {horseName && horseName + " "}at
        any time without any obligation to buy. Please contact us first on{" "}
        <a href="tel:+44154590503" style={{ color: textColor }}>
          (+44) 01545 590 503
        </a>
      </h2>
    </section>
  );
};
export default FullWidthCTA;
