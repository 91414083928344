import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css"; // Global styles
import reportWebVitals from "./reportWebVitals";

// Pages
import Home from "./Pages/Home/Home";
import Horses from "./Pages/Horses/Horses";
import Horse from "./Pages/Horse/Horse";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ForSale from "./Pages/ForSale/ForSale";
import ForSaleHorses from "./Pages/ForSaleHorses/ForSaleHorses";
import News from "./Pages/News/News";
import Contact from "./Pages/Contact/Contact";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import NewsArticle from "./Pages/NewsArticle/NewsArticle";
import CookieBanner from "./Components/CookieBanner/CookieBanner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <CookieBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="horses" element={<Horses />} />
        <Route path="horse/:id" element={<Horse />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="news" element={<News />} />
        <Route path="news/:id" element={<NewsArticle />} />
        <Route path="forSale" element={<ForSale />} />
        <Route path="forSale/:section" element={<ForSaleHorses />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
