import styles from "./css/NewsArticles.module.css";
import { Link } from "react-router-dom";
import useFetch from "../../Hooks/useFetch";
import { useEffect, useState } from "react";

const NewsArticles = ({
  perPage,
  showMoreLink = false,
  showSectionHeading = false,
}) => {
  const [articleEndpoint, setArticleEndpoint] = useState(
    "news-articles?populate=*&sort[0]=article_date:desc"
  );
  const [dataForPagination, setDataForPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (perPage) {
      setArticleEndpoint(
        `news-articles?populate=*&pagination[pageSize]=${perPage}&pagination[page]=${currentPage}&sort[0]=article_date:desc`
      );
    } else {
      setArticleEndpoint(
        `news-articles?populate=*&pagination[page]=${currentPage}&sort[0]=article_date:desc`
      );
    }
  }, [perPage, currentPage]);

  const { data: articles } = useFetch(articleEndpoint);

  useEffect(() => {
    if (!articles) return;
    setDataForPagination(articles);
    setTotalPages(articles.meta.pagination.pageCount);
    setCurrentPage(articles.meta.pagination.page);
  }, [articles]);

  return (
    <section className={styles.newsArticlesSection}>
      <div className="container">
        {showSectionHeading && (
          <h1 className={styles.sectionHeading}>Our Latest News</h1>
        )}
        <div className={styles.newsArticlesContainer}>
          {articles &&
            articles.data.map((article) => (
              <div
                className={styles.newsArticle}
                key={`article id: ${article.id}`}
              >
                <div className={styles.imageContainer}>
                  {article.attributes.featured_image.data.attributes.formats
                    ?.small ? (
                    <Link to={`/news/${article.id}`}>
                      <img
                        src={
                          process.env.REACT_APP_API_URI +
                          article.attributes.featured_image.data.attributes
                            .formats.small.url
                        }
                        alt={
                          article.attributes.featured_image.data.attributes
                            .alternativeText
                        }
                      />
                    </Link>
                  ) : (
                    <Link to={`/news/${article.id}`}>
                      <img
                        src={
                          process.env.REACT_APP_API_URI +
                          article.attributes.featured_image.data.attributes
                            .formats.thumbnail.url
                        }
                        alt={
                          article.attributes.featured_image.data.attributes
                            .alternativeText
                        }
                      />
                    </Link>
                  )}
                </div>
                <div className={styles.textContainer}>
                  <p className={styles.articleDate}>
                    {new Date(
                      article.attributes.article_date
                    ).toLocaleDateString("en-GB", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </p>
                  <h5 className={styles.articleTitle}>
                    {article.attributes.title.length >= 24
                      ? article.attributes.title.substring(0, 23) + " ..."
                      : article.attributes.title}
                  </h5>
                  <p className={styles.articleBlurb}>
                    {article.attributes.content.substring(0, 100) + "..."}
                  </p>
                  <Link
                    to={`/news/${article.id}`}
                    className={styles.articleButton}
                  >
                    READ ARTICLE
                  </Link>
                </div>
              </div>
            ))}
        </div>
        {showMoreLink ? (
          <p className={styles.moreNewsLink}>
            <Link to={"/news"}>View More News Posts</Link>
          </p>
        ) : (
          dataForPagination &&
          totalPages > 1 && (
            <div className={styles.pagination}>
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                &lt;
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default NewsArticles;
