import { useEffect, useState } from "react";
import styles from "./css/FamilyTree.module.css";

const FamilyTree = ({ horseId, horseName }) => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getParents(entryId) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/api/parents/${entryId}?populate=parents`
    );
    const entry = await response.json();

    if (
      !entry.data.attributes.parents.data ||
      entry.data.attributes.parents.data.length === 0
    ) {
      return [entry];
    }

    const parentPromises = entry.data.attributes.parents.data.map(
      async (parent) => {
        return getParents(parent.id);
      }
    );

    const parentEntries = await Promise.all(parentPromises);

    return [entry, ...parentEntries.flat()];
  }

  useEffect(() => {
    try {
      async function fetchData() {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URI}/api/parents?populate=parents&filters[horse][id][$eq]=${horseId}`
        );

        const apiResponse = await response.json();
        const fetchedEntries = apiResponse.data;

        const entriesWithParents = [];
        for (const entry of fetchedEntries) {
          const allParents = await getParents(entry.id);
          entriesWithParents.push(allParents);
        }
        setEntries(entriesWithParents);
        setLoading(false);
      }
      fetchData();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horseId]);

  return !loading ? (
    <section className={`${styles.breedingLineage} container`}>
      <h3>Pedigree</h3>
      <div className={styles.tableContainer}>
        <table>
          <tbody>
            <tr>
              <td rowSpan="8" className={styles.darkBg}>
                {horseName}
              </td>
              <td rowSpan="4" className={styles.darkBg}>
                {entries[0][0].data.attributes.name}
              </td>
              <td rowSpan="2" className={styles.darkBg}>
                {entries[0][1].data.attributes.name}
              </td>
              <td rowSpan="1" className={styles.darkBg}>
                {entries[0][2].data.attributes.name}
              </td>
            </tr>
            <tr>
              <td rowSpan="1"> {entries[0][3].data.attributes.name}</td>
            </tr>
            <tr>
              <td rowSpan="2"> {entries[0][4].data.attributes.name}</td>
              <td rowSpan="1" className={styles.darkBg}>
                {entries[0][5].data.attributes.name}
              </td>
            </tr>
            <tr>
              <td rowSpan="1"> {entries[0][6].data.attributes.name}</td>
            </tr>
            <tr>
              <td rowSpan="4">{entries[1][0].data.attributes.name}</td>
              <td rowSpan="2" className={styles.darkBg}>
                {entries[1][1].data.attributes.name}
              </td>
              <td rowSpan="1" className={styles.darkBg}>
                {entries[1][2].data.attributes.name}
              </td>
            </tr>
            <tr>
              <td rowSpan="1">{entries[1][3].data.attributes.name}</td>
            </tr>
            <tr>
              <td rowSpan="2">{entries[1][4].data.attributes.name}</td>
              <td rowSpan="1" className={styles.darkBg}>
                {entries[1][5].data.attributes.name}
              </td>
            </tr>
            <tr>
              <td rowSpan="1">{entries[1][6].data.attributes.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  ) : (
    ""
  );
};
export default FamilyTree;
