import styles from "./css/Contact.module.css";
import Footer from "../../Components/Footer/Footer";
import GoogleMap from "../../Components/GoogleMap/GoogleMap";
import NavBar from "../../Components/NavBar/NavBar";
import PageHeader from "../../Components/PageHeader/PageHeader";

const Contact = () => {
  return (
    <>
      <NavBar />
      <PageHeader title={"Contact Us"} />
      <div className="container">
        <section className={styles.contactUs}>
          <div className={styles.contactDetails}>
            <h2>Give us a call</h2>
            <p>
              <span>Tel:</span>{" "}
              <a href="tel:++441545590503">(+44) 01545 590 503</a>
            </p>
            <p className={styles.salesText}>
              For any sales enquiries call Thomas
            </p>
            <p>
              <span>Tel:</span>{" "}
              <a href="tel:++447581425875">(+44) 07581 425 875</a>
            </p>
            <h2>Write to us</h2>
            <p>
              <span>Address:</span>
            </p>
            <ul>
              <li>Menai &amp; Friarly Studs</li>
              <li>Pantydefaid</li>
              <li>Rhydowen</li>
              <li>Llandysul</li>
              <li>SA44 4QE</li>
            </ul>
          </div>
          <form className={styles.contactForm}>
            <h2>Send us a message</h2>
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" name="name" />

            <label htmlFor="lname">Your Email</label>
            <input type="email" id="email" name="email" />

            <label htmlFor="subject">Subject</label>
            <input type="text" name="subject" id="subject" />

            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
            ></textarea>

            <input type="submit" value="Send" />
          </form>
        </section>
      </div>
      <GoogleMap />
      <Footer />
    </>
  );
};
export default Contact;
